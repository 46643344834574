<template>
    <div id="calculations-page">
      <vs-alert v-if="!this.can_see_page" color="warning" :title="$t('AccessDeniedTitle')" active="true">
        <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
      </vs-alert>
      <template v-else>
        <vs-progress v-if="!loaded" indeterminate color="primary">primary</vs-progress>
        <template v-else>
          <div class="w-full flex">
            <div class="w-1/2 pr-8">
              <!-- first layer  -->
              <vs-table v-model="selectedLayer1" stripe pagination :max-items="10" search :data="portalLayers">
                <template slot="header">
                  <h4>{{ $t('selectFirstLayer') }}</h4>
                </template>
                <template slot="thead">
                  <vs-th class="pointer-events-none">{{$t('layer_name')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('layer_type')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('layer_size')}}</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ tr.layer_name }}</vs-td>
                    <vs-td>{{ tr.layer_type }}</vs-td>
                    <vs-td>{{ parseFloat(tr.layer_size) | prettyBytes }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <!-- second layer  -->
            <div class="w-1/2 pl-8" v-if="selectedLayer1 !== null">
              <vs-progress v-if="!collidingLayersLoaded" indeterminate color="primary">primary</vs-progress>
              <vs-alert v-else-if="!collidingLayers || collidingLayers === null || collidingLayers.length === 0" color="warning" active="true" class="mt-4">
                <p>{{ $t('noCollindingLayersFound') }}</p>
              </vs-alert>
              <vs-table v-else v-model="selectedLayer2" stripe pagination :max-items="10" search :data="collidingLayers" :key="selectedLayer1.layer_key">
                <template slot="header">
                  <h4>{{ $t('selectSecondLayer') }}</h4>
                </template>
                <template slot="thead">
                  <vs-th class="pointer-events-none">{{$t('layer_name')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('layer_type')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('layer_size')}}</vs-th>
                  <vs-th class="pointer-events-none">{{$t('overlap')}}</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ tr.layer.layer_name }}</vs-td>
                    <vs-td>{{ tr.layer.layer_type }}</vs-td>
                    <vs-td>{{ parseFloat(tr.layer.layer_size) | prettyBytes }}</vs-td>
                    <vs-td>
                      <vs-progress :percent="Number(tr.overlapPercent)*100"/>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
          <!-- settings -->
          <div v-if="readyToCompute" class="mt-10 text-center">
            <div class="w-1/3 inline-block">
              <h4 class="inline-flex mb-2">{{ $t('calculationSettings') }}</h4>
              <p>{{ $t('quality') }}</p>
              <vs-input-number v-model="quality" min="1" max="100" step="5"/>
              <hr>
              <p>{{ $t('densityUniformization') }}</p>
              <vs-switch v-model="densityUniformization" class="w-full mb-2"></vs-switch>             
              <hr>
              <p>{{ $t('cutLineWidth') }}</p>
              <vs-input-number v-model="cutLineWidth" min="0" max="10"/>
              <hr>
              <p>{{ $t('maxBodies') }}</p>
              <vs-input-number v-model="maxBodies" min="1" max="30"/>
              <hr>
              <p>{{ $t('bodiesVolMin') }}</p>
              <vs-input-number v-model="bodiesVolMin" min="1"/>
              <hr>
              <p>{{ $t('bodiesVolMax') }}</p>
              <vs-input-number v-model="bodiesVolMax" min="1"/>
              <hr>
              <p>{{ $t('zAxisVectorAuto') }}</p>
              <vs-switch square v-model="zAxisVectorAuto" class="w-full"></vs-switch>
            </div>
          </div>
          <!-- launcher -->
          <div class="w-full mt-10">
            <vs-button class="w-full" :disabled="!readyToCompute" @click="launchCompute">{{$t('Ok')}}</vs-button>
          </div>
        </template>
      </template>
    </div>
</template>
<script>
import axios from '@/axios.js'
import moment from 'moment' // for date comparaison
import logActivity from '@/logActivity.js'
import router from '@/router'

export default {
  components: {
  },
  data () {
    return {
      
      // ROYBON / API calls
      can_see_page: null,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getLayersOverlap: 'getLayersOverlap',
      getCollidingLayers: 'getCollidingLayers',
      getPortalLayers: 'getPortalLayers',

      // ROYBON / Layers
      loaded: false,
      collidingLayersLoaded: false,
      portalLayers: [],
      selectedLayer1: null,
      selectedLayer2: null,
      collidingLayers: [],
      readyToCompute: false,

      // settings
      quality: 50,
      densityUniformization: true,
      cutLineWidth: 1,
      maxBodies: 10,
      bodiesVolMin: 10,
      bodiesVolMax: 999999,
      zAxisVectorAuto: true
    }
  },
  methods: {    
    // ROYBON / Liste les portals ayant accès à ce project
    buildLayersList () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalLayers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.portalLayers = response.data
            this.loaded = true
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Retourne la liste des layers ayant un recouvrement avec le layer selectionné
    getColliding () {
      if (this.selectedLayer1 !== null) {
        const params = `portal_id=${this.$store.state.portal_id}&layer_key=${this.selectedLayer1.layer_key}`
        const rimnat_api_call_url = `${this.api_server_baseurl}/layer/${this.getCollidingLayers}?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response.data != null && response.data != 'null') {
              this.collidingLayers = response.data
              this.collidingLayersLoaded = true
            }
          })
          .catch((error)   => { console.log(error) })
      }
    },

    // ROYBON / Lance le calcul
    launchCompute () {
      if (this.readyToCompute === true && this.payload !== null) {
        const rimnat_api_call_url = `${this.api_server_baseurl}/calculation/addToQueue`
        const params = new FormData()
        for (const [key, value] of Object.entries(this.payload)) {
          params.set(key, JSON.stringify(value))
        }
        axios.post(rimnat_api_call_url, params)
          .then(() => {
            router.push(`/${this.$store.state.portal_slug}/calculations`)
          })
          .catch((error) => { console.error(error) })
      }
    },

    // ROYBON / Check si l'affichage de la page est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      const poc_start_date =    poc.can_portal_access_start_date
      const poc_end_date =      poc.can_portal_access_end_date
      if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
        if (poc_start_date !== null && moment().isSameOrBefore(poc_start_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
          this.AccessDeniedInfos = poc_start_date
        } else if (poc_end_date !== null && moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessExpired'
          this.AccessDeniedInfos = poc_end_date
        } else if (poc.can_portal_layers_manage !== '1') {
          this.can_see_page = false
          this.AccessDeniedMessage = 'cannotManageLayers'
        } else {
          this.can_see_page = true
        }
      } else {
        this.can_see_page = false
        this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
        this.AccessDeniedInfos = 'global or portal access invalid'
      }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },

  computed: {
    payload () {
      let data = null
      if (this.selectedLayer1 !== null &&  this.selectedLayer2 !== null) {
        try {
          data = {
            portal_id: parseInt(this.$store.state.portal_id),
            user_id: parseInt(this.$store.state.AppActiveUser.user_id),
            calc_type: 'mntdiff',
            input_0: {
              type: 'rimnat_layer',
              layerKey: this.selectedLayer1.layer_key
            },
            input_1: {
              type: 'rimnat_layer',
              layerKey: this.selectedLayer2.layer.layer_key
            },
            params: {
              quality: parseInt(this.quality),
              densityUniformization: this.densityUniformization,
              cutLineWidth: parseFloat(this.cutLineWidth),
              maxBodies: parseInt(this.maxBodies),
              bodiesVolMin: parseInt(this.bodiesVolMin),
              bodiesVolMax: parseInt(this.bodiesVolMax),
              zAxisVectorAuto: this.zAxisVectorAuto
            }
          }
        } catch (error) {
          console.error(error)
          data = null
        }
      }
      return data
    }
  },
  beforeMount () {
    this.canSeePage()
  },
  
  created () {
    this.buildLayersList()
  },
  
  mounted () {
    this.isMounted = true
  },

  watch: {
    can_see_page: function () {
      if (this.can_see_page) {
        logActivity.add('calculations_manager_access', 'success')
      } else {
        logActivity.add('calculations_manager_access_refused', 'error')
      }
    },
    selectedLayer1: function () {
      this.readyToCompute = false
      this.collidingLayersLoaded = false
      if (this.selectedLayer1 !== null && typeof this.selectedLayer1.layer_key !== 'undefined') {
        this.getColliding()
      }
    },
    selectedLayer2: function () {
      this.readyToCompute = false
      if (this.selectedLayer2 !== null && typeof this.selectedLayer2.layer.layer_key !== 'undefined') {
        if (this.selectedLayer1 !== null && typeof this.selectedLayer1.layer_key !== 'undefined') {
          this.readyToCompute = true
        }
      }
    },
    collidingLayers: function () {
    }
  }
}
</script>
